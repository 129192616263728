<template>
  <img
    loading="lazy"
    :class="[
      { arrow: true },
      direction ? 'arrow--' + direction : '',
      invert ? 'arrow--invert' : '',
    ]"
    :alt="getAltText()"
    src="@icons/arrow.svg"
  />
</template>

<script>
export default {
  props: {
    direction: { type: String, required: true },
    invert: { type: Boolean, default: false },
  },
  methods: {
    getAltText() {
      let text = 'En pil '
      switch (this.direction) {
        case 'right':
        case undefined:
          text += 'til højre'
          break
        case 'left':
          text += 'til venstre'
          break
        case 'up':
          text += 'op'
          break
        case 'down':
          text += 'ned'
          break
      }

      return text
    },
  },
}
</script>

<style lang="scss">
@import 'Arrow.scss';
</style>
