<script>
import { thousandSeparator } from '@/functions'

export default {
  template: '<span>{{ thousandSeparator(displayNumber) }}</span>',
  props: {
    number: { default: 0 },
  },
  data() {
    return {
      displayNumber: this.number ? this.number : 0,
      interval: false,
    }
  },
  watch: {
    number() {
      clearInterval(this.interval)

      if (this.number === this.displayNumber) {
        return
      }

      const owner = this
      this.interval = window.setInterval(() => {
        if (owner.displayNumber !== owner.number) {
          let change = (owner.number - owner.displayNumber) / 8
          change = change >= 0 ? Math.ceil(change) : Math.floor(change)
          owner.displayNumber = owner.displayNumber + change
        } else {
          clearInterval(owner.interval)
        }
      }, 12)
    },
  },
  methods: {
    thousandSeparator(value) {
      return thousandSeparator(value)
    },
  },
}
</script>
