<template>
  <div class="product-type">
    <List
      :type="type"
      :search-type="searchType"
      :items="items"
      :event-bus="proxyBus"
      :id="id"
    ></List>
  </div>
</template>

<script>
import mitt from 'mitt'

import List from '@search/List/List.vue'
import ListPropsMixin from '@search/List/list-props-mixin.vue'

export default {
  name: 'ProductType',
  mixins: [ListPropsMixin],
  components: {
    List,
  },
  data() {
    return {
      proxyBus: mitt(),
    }
  },
  props: {
    eventBus: { default: null, type: Object },
    type: { default: 'checkbox', type: String },
    searchType: { default: null, type: String },
    id: { default: '', type: String },
    items: { default: null, type: Array },
  },
  methods: {
    productTypeSelected(options) {
      // report for seachRequest update but without summary
      options.summaryOptions = []
      this.eventBus.emit('search-request-callback', options)

      if (options.url === undefined) return

      window.location.href = options.url
    },
  },
  created() {
    this.proxyBus.on('search-request-callback', this.productTypeSelected)
  },
}
</script>

<style lang="scss">
@import 'ProductType.scss';
</style>
