<script>
import { v4 as uuidv4 } from 'uuid'

export default {
  data() {
    return {
      uid: null,
    }
  },
  mounted() {
    this.uid = uuidv4()
  },
}
</script>
