<template>
  <div class="price" ref="priceSingleSelect">
    <List
      ref="list"
      :type="'checkbox'"
      :single-select="true"
      :search-type="searchType"
      :items="priceTypesSimple"
      :event-bus="proxyBus"
      :id="id"
    ></List>
    <div
      class="price__container"
      v-for="(priceType, index) in priceTypes"
      :key="priceType.value"
      v-show="index === activeIndex"
    >
      <component
        v-for="child in priceType.children"
        v-bind="child.props"
        :key="child.props.searchType"
        :is="child.type"
        :id="id"
        :is-hidden="index !== activeIndex"
        :event-bus="proxyPriceBus"
        :ref="child.props.searchType"
      ></component>
    </div>
  </div>
</template>

<script>
import mitt from 'mitt'

import List from '@search/List/List.vue'
import Slider from '@search/Slider/Slider.vue'
import UidMixin from '@shared/uid-mixin.vue'
import { activateSingleSelect, thousandSeparator } from '@/functions'

export default {
  name: 'Price',
  mixins: [UidMixin],
  components: {
    List,
    Slider,
  },
  props: {
    priceTypes: { default: null, type: Array },
    eventBus: { default: null, type: Object },
    searchType: { default: null, type: String },
    id: { default: '', type: String },
  },
  data() {
    return {
      activeIndex: null,
      searchRequests: [],
      proxyBus: null,
      proxyPriceBus: null,
      priceTypesSimple: [],
      paymentTypeId: undefined,
      isRegistrationTaxIncluded: undefined,
      isVatIncluded: undefined,
      max: undefined,
      min: undefined,
      downPaymentMin: undefined,
      downPaymentMax: undefined,
      priceType: '',
      isLoaded: false,
    }
  },
  methods: {
    getSliderTypeName(children) {
      let searchType
      children.forEach(child => {
        if (child.type.toLowerCase() == 'slider') {
          searchType = child.props.searchType
        }
      })

      return searchType
    },
    getCurrentSearchRequest() {
      return this.searchRequests[this.activeIndex]
    },
    createRequestItem() {
      if (this.activeIndex == null) return null

      const tempPriceType = this.priceTypes[this.activeIndex]
      if (tempPriceType == null) return null

      const tempSliderTypeName = this.getSliderTypeName(tempPriceType.children)

      let item = {}

      const currentSearchRequest = this.getCurrentSearchRequest()
      const sliderRequestIndex = currentSearchRequest.findIndex(
        x => x.type === tempSliderTypeName
      )
      if (sliderRequestIndex !== -1) {
        item = this.searchRequests[this.activeIndex][sliderRequestIndex]
      }

      // Get payment type (kontant / leasing)
      item.paymentTypeId = tempPriceType.value
      item.paymentTypeName = tempPriceType.name

      // Resetting isRegistrationTaxIncluded to null before traversing searchRequest
      delete item.isRegistrationTaxIncluded

      this.searchRequests[this.activeIndex].forEach(requestItem => {
        if (requestItem.type != tempSliderTypeName) {
          item[requestItem.type] = requestItem.value
        }
      })

      return item
    },
    emitValues(item) {
      const requestItem = this.createRequestItem()

      if (requestItem !== null) {
        if (requestItem !== null && requestItem.paymentTypeId !== undefined) {
          this.paymentTypeId = requestItem.paymentTypeId
        }

        if (item.summaryOptions[0] !== undefined) {
          const summaryValue = item.summaryOptions[0].value
          if (item.searchType === 'isRegistrationTaxIncluded') {
            if (item.selected) {
              this.isRegistrationTaxIncluded = summaryValue
            } else {
              this.isRegistrationTaxIncluded = undefined
            }
          }

          if (item.searchType === 'isVatIncluded') {
            if (item.selected) {
              this.isVatIncluded = summaryValue
            } else {
              this.isVatIncluded = undefined
            }
          }
        }

        if (
          item.searchType === 'price-cash-slider' ||
          item.searchType === 'price-lease-slider'
        ) {
          if (item.value.min !== undefined) {
            this.min = item.value.min
          } else {
            this.min = undefined
          }

          if (item.value.max !== undefined) {
            this.max = item.value.max
          } else {
            this.max = undefined
          }
        }

        if (item.searchType === 'price-lease-slider-downpayment') {
          if (item.value.min !== undefined) {
            this.downPaymentMin = item.value.min
          } else {
            this.downPaymentMin = undefined
          }

          if (item.value.max !== undefined) {
            this.downPaymentMax = item.value.max
          } else {
            this.downPaymentMax = undefined
          }
        }

        let value = {},
          hasValue = false

        if (this.isRegistrationTaxIncluded !== undefined) {
          value.isRegistrationTaxIncluded = this.isRegistrationTaxIncluded
          hasValue = true
        }

        if (this.isVatIncluded !== undefined) {
          value.isVatIncluded = this.isVatIncluded
          hasValue = true
        }

        if (this.max !== undefined) {
          value.max = this.max
          hasValue = true
        }

        if (this.min !== undefined) {
          value.min = this.min
          hasValue = true
        }

        if (this.downPaymentMin !== undefined) {
          value.downPaymentMin = this.downPaymentMin
          hasValue = true
        }

        if (this.downPaymentMax !== undefined) {
          value.downPaymentMax = this.downPaymentMax
          hasValue = true
        }

        if (this.paymentTypeId !== undefined) {
          value.paymentTypeId = this.paymentTypeId
          hasValue = true
        }

        if (!hasValue) {
          value = undefined
        }

        this.eventBus.emit('search-request-callback', {
          value: value,
          searchType: this.searchType,
          selected: value !== undefined, // hardcoded to true since child component can be deselected
          summaryOptions: this.getSummaryOptions(),
        })
      } else {
        this.eventBus.emit('search-request-callback', {
          value: {}, // yes, this should be an object
          searchType: this.searchType,
          selected: item.selected,
          summaryOptions: [
            {
              name: '',
              value: item.value,
              clearSelf: this.clear,
            },
          ],
        })
      }
    },
    typeSearchRequestCallback(item) {
      if (item.value === undefined) return

      if (item.summaryOptions.length !== 0) {
        this.priceType = item.summaryOptions[0].name
        this.clearChildren()
      }

      if (!item.selected) {
        this.activeIndex = null
      } else {
        this.activeIndex = this.priceTypes.findIndex(
          x => x.value === item.value
        )
      }

      this.emitValues(item)
    },
    ///***** Checking if the callbackItem is valid for submit *****///
    /// Due to v-show, several price sliders will load. Each of these will report the current status, and the last to report, will dictate the min and max values of this component.
    /// The check only catch callbackItems from sliders within priceTypes not being of the activeIndex - these will be passed as not submitable
    isValidCallbackItem(callbackItem) {
      let submit = true

      this.priceTypes.forEach((priceType, index) => {
        let currentSliderName = this.getSliderTypeName(priceType.children)
        if (!currentSliderName) return
        if (
          currentSliderName === callbackItem.searchType &&
          index !== this.activeIndex
        ) {
          submit = false
        }
      })

      return submit
    },
    priceSearchRequestCallback(item) {
      if (!this.isValidCallbackItem(item)) return

      this.emitValues(item)
    },
    getSummaryOptions() {
      let priceItem = this.createRequestItem()

      if (priceItem === null) return [{ type: this.searchType }]

      let summaryItemName = ''
      if (priceItem.paymentTypeId !== undefined) {
        summaryItemName += priceItem.paymentTypeName // 'Kontakt' fx.
      }

      if (
        ((this.min !== null && this.min !== undefined) ||
          (this.max !== null && this.max !== undefined)) &&
        this.priceType === 'Leasing'
      ) {
        summaryItemName += ' pr. md.'
      }

      if (this.min !== null && this.min !== undefined) {
        summaryItemName += ` fra ${thousandSeparator(this.min)} kr.`
      }

      if (this.max !== null && this.max !== undefined) {
        summaryItemName += ` op til ${thousandSeparator(this.max)} kr.`
      }

      if (
        (this.downPaymentMin !== null && this.downPaymentMin !== undefined) ||
        (this.downPaymentMax !== null && this.downPaymentMax !== undefined)
      ) {
        if (
          (this.min !== null && this.min !== undefined) ||
          (this.max !== null && this.max !== undefined)
        ) {
          summaryItemName += ' -'
        }

        summaryItemName += ' udbetaling'
      }

      if (this.downPaymentMin !== null && this.downPaymentMin !== undefined) {
        summaryItemName += ` fra ${thousandSeparator(this.downPaymentMin)} kr.`
      }

      if (this.downPaymentMax !== null && this.downPaymentMax !== undefined) {
        summaryItemName += ` op til ${thousandSeparator(
          this.downPaymentMax
        )} kr.`
      }

      if (this.isVatIncluded !== undefined && this.priceType === 'Kontant') {
        if (this.isVatIncluded) {
          summaryItemName += ' inkl. moms'
        } else {
          summaryItemName += ' ekskl. moms'
        }
      }

      if (
        this.isRegistrationTaxIncluded !== undefined &&
        this.priceType === 'Kontant'
      ) {
        if (this.isRegistrationTaxIncluded) {
          summaryItemName += ' inkl. reg.afgift'
        } else {
          summaryItemName += ' ekskl. reg.afgift'
        }
      }

      return [
        { name: summaryItemName, type: this.searchType, clearSelf: this.clear },
      ]
    },
    clear() {
      this.activeIndex = null
      this.priceTypesSimple.forEach(i => {
        this.$refs.list.clear(i)
      })
      this.clearChildren()
    },
    clearChildren() {
      if (!this.isLoaded) return

      this.priceTypes.forEach(type => {
        type.children.forEach(child => {
          this.$refs[child.props.searchType].clear()
        })
      })
    },
  },
  mounted() {
    activateSingleSelect(this.$refs.priceSingleSelect)
  },
  created() {
    this.proxyBus = mitt()
    this.proxyBus.on('search-request-callback', this.typeSearchRequestCallback)

    this.proxyPriceBus = mitt()
    this.proxyPriceBus.on(
      'search-request-callback',
      this.priceSearchRequestCallback
    )

    this.activeIndex = this.priceTypes.findIndex(x => x.selected)
    if (this.activeIndex === -1) this.activeIndex = null

    const keys = Object.keys(this.searchType)
    keys.forEach(() => {
      this.searchRequests.push([])
    })

    // copy priceType and remove children to use for list
    this.priceTypes.forEach(priceType => {
      const priceTypeCopy = JSON.parse(JSON.stringify(priceType))
      this.priceTypesSimple.push(priceTypeCopy)
    })

    setTimeout(() => {
      this.isLoaded = true
    }, 150)
  },
}
</script>

<style lang="scss">
@import 'Price.scss';
</style>
