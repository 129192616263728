<template>
  <TransitionRoot as="template" :show="isOpen">
    <Dialog class="relative z-50" @close="isOpen = false">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-50 w-screen overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full"
              :class="{
                'sm:max-w-3xl': modalSize === 'large',
                'sm:max-w-lg': modalSize === 'regular',
              }"
            >
              <div class="bg-white py-8 px-6 md:p-12">
                <slot></slot>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { app } from '@/main'
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'

export default {
  components: {
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
  },
  props: {
    eventBus: { default: null, type: Object },
    modalSize: { default: 'regular', type: String },
  },
  data() {
    return {
      isOpen: false,
      uid: null,
    }
  },
  methods: {
    checkForEscKey(evt) {
      if (evt == null || evt == null) return false
      if (evt.keyCode == 27) {
        this.close()
      }
    },
    getFormContent() {
      return this.$el.querySelector('.dropdown-container__content')
    },
    open() {
      const owner = this
      this.isOpen = true

      app.history.push(() => {
        if (owner.eventBus) {
          owner.eventBus.emit('overlay-closing')
        }
        owner.isOpen = false
      })
      if (this.eventBus === null) return
      this.eventBus.emit('overlay-open')
    },
    close() {
      app.history.pop()
    },
  },
  watch: {
    isOpen(newValue) {
      if (newValue) {
        document.body.style.overflow = 'hidden'
        document.addEventListener('keydown', this.checkForEscKey)
      } else {
        document.body.removeAttribute('style')
        document.removeEventListener('keydown', this.checkForEscKey)
      }
    },
  },
  mounted() {
    const form = document.getElementsByClassName('dropdown-container')[0]
    if (form == null) return
    document.body.appendChild(form)
  },
  created() {
    this.uid = this._uid
  },
}
</script>

<style lang="scss">
@import 'DropdownContainer.scss';
</style>
