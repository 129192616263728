<template>
  <div class="overlay-product-type">
    <Overlay
      class="overlay-product-type--overlay"
      :entries="entries"
      :title="title"
      :id="id"
      :children="children"
      :event-bus="eventBus"
      :selected-title="selectedTitle"
      :hide-select-text="hideSelectText"
      :product-type-id="productTypeId"
      :ads-count="adsCount"
      :search-request="searchRequest"
      :hide-counter="true"
    >
    </Overlay>
    <ProductTypeListHorizontal
      class="overlay-product-type--list-horizontal"
      v-for="component in children"
      v-bind="component.props"
      :key="component.id"
      :event-bus="proxyBus"
    ></ProductTypeListHorizontal>
  </div>
</template>

<script>
import mitt from 'mitt'

import Overlay from '@shared/Overlay/Overlay.vue'
import ProductTypeListHorizontal from '@search/ProductTypeListHorizontal/ProductTypeListHorizontal.vue'

import UidMixin from '@shared/uid-mixin.vue'
import SearchComponentsMixin from '@search/search-components-mixin.vue'

export default {
  mixins: [SearchComponentsMixin, UidMixin],
  data() {
    return {
      isDisabled: false,
      isOpen: false,
      isChildOpen: false,
      proxyBus: mitt(),
    }
  },
  components: {
    Overlay,
    ProductTypeListHorizontal,
  },
  props: {
    entries: {
      default: () => {
        return []
      },
      type: Array,
    },
    title: { default: '', type: String },
    id: { default: '', type: String },
    children: { default: null, type: Array },
    eventBus: { default: null, type: Object },
    selectedTitle: { default: null, type: String },
    hideSelectText: { default: false, type: Boolean },
    productTypeId: { default: null, type: Object },
    adsCount: { default: 0, type: Number },
    searchRequest: { default: null, type: Array },
  },
  watch: {
    children: {
      deep: true,
      handler() {
        this.updateDisabledState()
      },
    },
  },
  methods: {
    emitState(stateChange) {
      this.eventBus.emit(stateChange, this)
    },
    hasOverlayChildren() {
      return !this.children || this.children.find(e => e.type === 'Overlay')
    },
    updateDisabledState() {
      if (this.children === null) return

      let isDisabled = false
      this.children.forEach(child => {
        if (child.type === 'ModelList') {
          if (child.props.items.length === 0) {
            isDisabled = true
          }
        }
      })

      this.isDisabled = isDisabled
    },
    productTypeSelected(options, selectedItem) {
      if (selectedItem == null || typeof selectedItem.url !== 'string') return
      window.location.href = selectedItem.url
    },
  },
  created() {
    this.updateDisabledState()
    this.proxyBus.on('search-request-callback', this.productTypeSelected)
  },
}
</script>

<style lang="scss">
@import 'OverlayProductType.scss';
</style>
