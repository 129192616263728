/**************************************************************************************************************************************/
/* Used by overlays with slider, to ensure the overlay doesn't close if adjusting a slider make the cursor end up outside the overlay */
/**************************************************************************************************************************************/

import { app } from '@/main'

import { debounce } from '@/functions'

const setClosable = debounce(() => {
  app.closable = true
}, 150)
const setInclosable = debounce(() => {
  app.closable = false
}, 150)

const initOverlayStatus = () => {
  app.closable = true

  app.mitt.on('overlay-closable', setClosable)
  app.mitt.on('overlay-inclosable', setInclosable)
}

export { initOverlayStatus }
