<script>
export default {
  props: {
    description: { default: null, type: String },
    minVal: { default: 0, type: Number },
    maxVal: { default: 999, type: Number },
    selectedMin: { default: null, type: Number },
    selectedMax: { default: null, type: Number },
    minValLimit: { default: 0, type: Number },
    maxValLimit: { default: null, type: Number },
    minInputHeader: { default: null, type: String },
    maxInputHeader: { default: null, type: String },
    minBoundsBrokenText: { default: 'Under', type: String },
    maxBoundsBrokenText: { default: 'Over', type: String },
    interval: { default: 1, type: Number },
    valPostfix: { default: null, type: String },
    valSingularityPostfix: { default: null, type: String },
    lockedInterval: { default: false, type: Boolean },
    forcedDecimal: { default: true, type: Boolean },
    showThousandSeparator: { default: false, type: Boolean },
    eventBus: { default: null, type: Object },
    searchType: { default: null, type: String },
  },
}
</script>
