<template>
  <div class="product-type-list-horizontal">
    <ListHorizontal
      class="overlay-product-type--list-horizontal"
      :type="type"
      :search-type="searchType"
      :event-bus="proxyBus"
      :items="items"
      :single-select="singleSelect"
    ></ListHorizontal>
  </div>
</template>

<script>
import mitt from 'mitt'

import ListHorizontal from '@search/ListHorizontal/ListHorizontal.vue'

import ListPropsMixin from '@search/List/list-props-mixin.vue'
import UidMixin from '@shared/uid-mixin.vue'

export default {
  mixins: [ListPropsMixin, UidMixin],
  name: 'ProductTypeListHorizontal',
  // included to satisfy typescript
  props: {
    type: { default: 'radio', type: String },
    searchType: { default: null, type: String },
    id: { default: '', type: String },
    eventBus: { default: null, type: Object },
    items: { default: null, type: Array },
    singleSelect: { default: false, type: Boolean },
  },
  data() {
    return {
      highlight: `${this.type} ${this.type}--highlight`,
      proxyBus: mitt(),
    }
  },
  components: {
    ListHorizontal,
  },
  methods: {
    productTypeSelected(options) {
      // report for seachRequest update but without summary
      options.summaryOptions = []
      this.eventBus.emit('search-request-callback', options)

      if (options.url === undefined) return

      window.location.href = options.url
    },
  },
  created() {
    this.proxyBus.on('search-request-callback', this.productTypeSelected)
  },
}
</script>

<style lang="scss">
@import 'ProductTypeListHorizontal.scss';
</style>
