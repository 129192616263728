<template>
  <div class="textsearch textsearch-focused">
    <label class="sr-only" for="textSearch_input" v-html="title"></label>
    <div class="input input--search">
      <input
        class="input--search"
        id="textSearch_input"
        type="text"
        autocomplete="off"
        v-model="searchValue"
        :title="decodeHTMLEntities(title)"
        :placeholder="decodeHTMLEntities(title)"
        @keyup.enter="handlePerformSearch"
        @input="prepareSearchValue"
        ref="textSearchInput"
      />
      <span class="input--search-icon" @click="handlePerformSearch"></span>
      <ClearInput
        :value="searchValue"
        :event-bus="eventBus"
        :fixed-search-icon="true"
      />
    </div>
  </div>
</template>

<script>
import { debounce, decodeHTMLEntities } from '@/functions'

import ClearInput from '@shared/ClearInput/ClearInput.vue'

export default {
  name: 'TextSearch',
  components: {
    ClearInput,
  },
  props: {
    title: { default: 'Søg...', type: String },
    value: { default: '', type: String },
    eventBus: { default: null, type: Object },
    searchType: { default: null, type: String },
  },
  data() {
    return {
      searchValue: '',
    }
  },
  methods: {
    decodeHTMLEntities(value) {
      return decodeHTMLEntities(value)
    },
    emitSearchValue() {
      this.eventBus.emit('search-request-callback', {
        value: this.searchValue,
        searchType: this.searchType,
        summaryOptions: [
          {
            name: this.searchValue,
            value: this.searchType,
            clearSelf: this.clearSearchString,
          },
        ],
      })
    },
    prepareSearchValue() {
      this.prepareSearchValueHandler(this)
    },
    prepareSearchValueHandler: debounce(owner => {
      owner.emitSearchValue(owner.handlePrepareSearchValue)
    }, 300),
    clearSearchString() {
      this.searchValue = ''
      this.prepareSearchValue()

      this.$refs.textSearchInput.focus()
    },
    handlePerformSearch(searchRequest, options, uiComponent, item) {
      this.emitSearchValue(searchRequest, options, uiComponent, item)

      this.eventBus.emit('create-search')
    },
    clear() {
      this.searchValue = ''
      this.prepareSearchValue()
    },
  },
  created() {
    this.searchValue = decodeHTMLEntities(this.value)

    if (this.searchValue !== '' && this.searchValue !== null) {
      this.emitSearchValue()
    }

    this.eventBus.on('clear-input-string', this.clearSearchString)
  },
}
</script>

<style lang="scss">
@import 'TextSearch.scss';
</style>
