<template>
  <span>
    <span
      v-if="!value"
      :class="{
        'input--search-icon': searchIcon,
        'input--location-icon': locationIcon,
      }"
    ></span>
    <span
      class="input--clear-icon"
      :class="{
        'icon--hidden': !value,
        'input--fixed-search-icon-present': fixedSearchIcon,
      }"
      @click="clearInput()"
    >
      <span class="icon--plus icon--white icon--contained icon--rotate"></span>
    </span>
  </span>
</template>

<script>
export default {
  props: {
    value: { required: true, type: String },
    eventBus: { required: true, default: null, type: Object },
    fixedSearchIcon: { default: false, type: Boolean },
    searchIcon: { default: false, type: Boolean },
    locationIcon: { default: false, type: Boolean },
  },
  methods: {
    clearInput() {
      this.eventBus.emit('clear-input-string')
    },
  },
}
</script>
