<script>
import { app } from '@/main'

export default {
  error(msg) {
    app.mitt.emit('overlay-inclosable', this)
    app.$alert(msg, '', 'error', {
      showConfirmButton: false,
      buttonsStyling: false,
      timer: 5000,
      onClose: () => {
        app.mitt.emit('overlay-closable', this)
      },
    })
  },
  customHtmlToast(
    html,
    cancelButtonName,
    confirmButtonName,
    confirmButtonCallback
  ) {
    app.mitt.emit('overlay-inclosable', this)
    app.$alert('', '', 'info', {
      html: html,
      showCancelButton: cancelButtonName !== '',
      cancelButtonText: cancelButtonName,
      showConfirmButton: confirmButtonName !== '',
      confirmButtonText: confirmButtonName,
      reverseButtons: true,
      buttonsStyling: false,
      customClass: {
        cancelButton: 'toast--override-cancel',
        confirmButton: 'toast--override-confirm',
      },
      preConfirm: () => {
        return confirmButtonCallback()
      },
      onClose: () => {
        app.mitt.emit('overlay-closable', this)
      },
    })
  },
  customWarning(data) {
    app.mitt.emit('overlay-inclosable', this)
    app.$alert(data.message, '', 'error', {
      showConfirmButton: true,
      showCancelButton: false,
      buttonsStyling: false,
      confirmButtonText: data.buttonText,
      preConfirm: () => {
        window.location.href = data.buttonLink
      },
      onClose: () => {
        app.mitt.emit('overlay-closable', this)
      },
    })
  },
  warning(msg, callback, confirmBtnTxtStr, cancelBtnTxtStr, close) {
    app.mitt.emit('overlay-inclosable', this)
    app.$alert(msg, '', 'error', {
      showConfirmButton: callback != undefined,
      showCancelButton: true,
      confirmButtonText: this.getLabelText(confirmBtnTxtStr, 'Læs mere'),
      cancelButtonText: this.getLabelText(cancelBtnTxtStr, 'Luk'),
      buttonsStyling: false,
      customClass: {
        popup: 'toast--warning',
      },
      preConfirm: () => {
        if (callback != undefined) {
          callback()
        }
      },
      onClose: () => {
        app.mitt.emit('overlay-closable', this)
        if (close != undefined) {
          close()
        }
      },
    })
  },
  info(msg, callback, confirmBtnTxtStr, cancelBtnTxtStr) {
    app.mitt.emit('overlay-inclosable', this)
    app.$alert(msg, '', 'info', {
      showConfirmButton: callback != undefined,
      showCancelButton: true,
      confirmButtonText: this.getLabelText(confirmBtnTxtStr, 'Læs mere'),
      cancelButtonText: this.getLabelText(cancelBtnTxtStr, 'Luk'),
      buttonsStyling: false,
      customClass: {
        actions: 'reverse-actions',
      },
      preConfirm: () => {
        if (callback != undefined) {
          callback()
        }
      },
      onClose: () => {
        app.mitt.emit('overlay-closable', this)
      },
    })
  },
  success(msg, confirmBtnTxtStr, ignoreTimer, callback) {
    app.mitt.emit('overlay-inclosable', this)
    app.$alert(msg, '', 'success', {
      showConfirmButton: confirmBtnTxtStr ? true : false,
      buttonsStyling: false,
      confirmButtonText: this.getLabelText(confirmBtnTxtStr, 'Luk'),
      timer: ignoreTimer ? 0 : 2500,
      preConfirm: () => {
        if (callback != undefined) {
          callback()
        }
      },
      onClose: () => {
        app.mitt.emit('overlay-closable', this)
      },
    })
  },
  confirm(msg, confirmBtnTxtStr, cancelBtnTxtStr) {
    app.mitt.emit('overlay-inclosable', this)
    app.$confirm(msg, '', 'info', {
      buttonsStyling: false,
      confirmButtonText: this.getLabelText(confirmBtnTxtStr, 'Fortsæt'),
      cancelButtonText: this.getLabelText(cancelBtnTxtStr, 'Annullér'),
      onClose: () => {
        app.mitt.emit('overlay-closable', this)
      },
    })
  },
  getLabelText(input, defaultStr) {
    if (input == undefined || input == '') {
      return defaultStr
    }
    return input
  },
}
</script>
