<template>
  <div class="button-spinner__container">
    <div class="button-spinner__loader"></div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
@import 'ButtonSpinner.scss';
</style>
