<template>
  <p class="search-counter">
    <AnimatedNumber :number="adsCount" /> {{ productTypeName }}
  </p>
</template>

<script>
import AnimatedNumber from '@shared/AnimatedNumber/AnimatedNumber.vue'

export default {
  components: {
    AnimatedNumber,
  },
  props: {
    adsCount: { default: 0, type: Number },
    productTypeNameSingular: { default: '', type: String },
    productTypeNamePlural: { default: '', type: String },
  },
  computed: {
    productTypeName() {
      return this.adsCount === 1
        ? this.productTypeNameSingular
        : this.productTypeNamePlural
    },
  },
}
</script>

<style lang="scss">
@import 'SearchCounter.scss';
</style>
