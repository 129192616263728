<template>
  <div class="slider">
    <span class="slider__name" v-if="description">{{ description }}</span>
    <div class="slider__panel-headers" v-if="minInputHeader || maxInputHeader">
      <span v-if="minInputHeader">{{ minInputHeader }}</span>
      <span v-if="maxInputHeader" class="slider--right">{{
        maxInputHeader
      }}</span>
    </div>
    <div class="slider__panel">
      <div class="slider__panel-container">
        <input
          type="text"
          aria-label="Min"
          v-model="tempValue[0]"
          @mousedown="keepOpenOnSelect()"
          @focus="setDigitOnFocus(false)"
          @blur="correctValues(false)"
          @keyup="changedByUser(false, $event)"
          :ref="searchType + '_min'"
        />
        <span class="slider__placeholder slider__placeholder--left">
          {{
            formattedValue(
              value[0],
              forcedDecimal && isDecimal(options.interval),
              showThousandSeparator,
              value[0] == options.min && options.min != 0,
              minBoundsBrokenText,
              interval
            )
          }}
          <span
            class="slider__postfix"
            v-if="valPostfix && !(valSingularityPostfix && value[0] == 1)"
          >
            {{ valPostfix }}
          </span>
          <span
            class="slider__postfix"
            v-if="valSingularityPostfix && value[0] == 1"
          >
            {{ valSingularityPostfix }}
          </span>
        </span>
      </div>
      <div class="slider__panel-container">
        <input
          type="text"
          aria-label="Max"
          v-model="tempValue[1]"
          @mousedown="keepOpenOnSelect()"
          @keyup="changedByUser(true, $event)"
          @focus="setDigitOnFocus(true)"
          @blur="correctValues(true)"
          :ref="searchType + '_max'"
          class="slider--right"
        />
        <span class="slider__placeholder slider__placeholder--right">
          {{
            formattedValue(
              value[1],
              forcedDecimal && isDecimal(options.interval),
              showThousandSeparator,
              value[1] == options.max,
              maxBoundsBrokenText,
              interval,
              true
            )
          }}
          <span
            class="slider__postfix slider--right"
            v-if="valPostfix && !(valSingularityPostfix && value[1] == 1)"
          >
            {{ valPostfix }}
          </span>
          <span
            class="slider__postfix slider--right"
            v-if="valSingularityPostfix && value[1] == 1"
          >
            {{ valSingularityPostfix }}
          </span>
        </span>
      </div>
    </div>
    <vue-slider
      class="slider__component"
      ref="slider"
      v-model="value"
      v-bind="options"
      @drag-start="dragStart"
      @drag-end="dragEnd"
    ></vue-slider>
  </div>
</template>

<script>
import { app } from '@/main'

import VueSlider from 'vue-slider-component'

import { thousandSeparator } from '@/functions'

import SliderPropsMixin from '@search/Slider/slider-props-mixin.vue'

export default {
  mixins: [SliderPropsMixin],
  name: 'Slider',
  components: {
    VueSlider,
  },
  props: {
    title: { default: null, type: String },
    description: { default: null, type: String },
    minVal: { default: 0, type: Number },
    maxVal: { default: 999, type: Number },
    selectedMin: { default: null, type: Number },
    selectedMax: { default: null, type: Number },
    minValLimit: { default: 0, type: Number },
    maxValLimit: { default: 90000000, type: Number },
    minInputHeader: { default: null, type: String },
    maxInputHeader: { default: null, type: String },
    minBoundsBrokenText: { default: 'Under', type: String },
    maxBoundsBrokenText: { default: 'Over', type: String },
    interval: { default: 1, type: Number },
    valPostfix: { default: null, type: String },
    valSingularityPostfix: { default: null, type: String },
    lockedInterval: { default: false, type: Boolean },
    forcedDecimal: { default: true, type: Boolean },
    showThousandSeparator: { default: false, type: Boolean },
    eventBus: { default: null, type: Object },
    searchType: { default: null, type: String },
    id: { default: '', type: String },
    isHidden: { default: false, type: Boolean },
  },
  data() {
    return {
      tempValue: [],
      value: [0, 4],
      options: {
        dotSize: 30,
        width: 'auto',
        height: 4,
        direction: 'ltr',
        data: null,
        min: 0,
        max: 100,
        interval: 1,
        clickable: true,
        duration: 0.5,
        minRange: 0,
        useKeyboard: true,
        disabled: false,
        enableCross: true,
      },
      tempValueHandlerTimeout: 0,
      valueHandlerTimeout: 0,
      minBoundsBroken: false,
      maxBoundsBroken: false,
      minValueCodeChanged: false,
      maxValueCodeChanged: false,
      postBlurChangesDone: false,
      minValueChangedByUser: false,
      maxValueChangedByUser: false,
      isMounted: false,
    }
  },
  methods: {
    formattedValue(
      value,
      forcedDecimal,
      showThousandSeparator,
      passedBounds,
      prefix,
      interval,
      isMaxVal
    ) {
      let tempValue = value
      if (passedBounds) {
        tempValue += isMaxVal ? interval * -1 : interval
      }

      if (showThousandSeparator) {
        return (
          (passedBounds ? prefix + ' ' : '') +
          (forcedDecimal
            ? tempValue.toLocaleString('da-DK', { minimumFractionDigits: 1 })
            : tempValue.toLocaleString('da-DK'))
        )
      }

      const val = forcedDecimal
        ? tempValue.toFixed(1).replace('.', ',')
        : tempValue
      return (passedBounds ? prefix + ' ' : '') + val
    },
    getFloat(val) {
      return parseFloat(this.sanitizeNumberString(val))
    },
    asFormattedString(val) {
      return val.toString().replace('.', ',')
    },
    isDecimal(val) {
      return this.getFloat(val.toString()) % 1 != 0
    },
    // fixing decimal number to one decimal digit
    fixDecimal(val) {
      if (!this.isDecimal(val.toString())) return this.getFloat(val.toString())

      const decimalValue = parseInt(
        ((this.getFloat(val.toString()) * 10) % 10).toString()
      )

      const fixedValue =
        parseInt(val.toString()).toString() + '.' + decimalValue.toString()
      if (fixedValue == '0.0') return 0
      return this.getFloat(fixedValue)
    },
    fixToInterval(val, isMaxVal) {
      const tempInterval = this.options.interval * 10

      let tempVal =
        (val == null
          ? isMaxVal
            ? this.fixDecimal(this.options.max)
            : this.fixDecimal(this.options.min)
          : this.fixDecimal(val)) * 10
      while (tempVal % tempInterval != 0) {
        if (isMaxVal) {
          tempVal++
        } else {
          tempVal--
        }
      }
      return tempVal / 10
    },
    // sanitizing inputted value, removing all but numbers, comma and dot
    sanitizeNumberString(val) {
      const replacedStr = this.replaceAll(
        val.toString().replace(/[^0-9.,]+/, ''),
        ',',
        '.'
      )
      const strArr = replacedStr.split('.')
      const result =
        strArr.shift() + '' + (strArr.length ? '.' + strArr.join('') : '')
      return result
    },
    // somehow extended version of replace, handling all matching cases
    replaceAll(val, search, replacement) {
      return val.split(search).join(replacement)
    },
    changedByUser(isMaxVal, event) {
      // Ignore if keyUp is tab and shift (shift + tab)
      if (event?.keyCode == 9 || event?.keyCode == 16) return

      if (!isMaxVal) {
        this.minValueCodeChanged = false
        this.minValueChangedByUser = true
      } else if (isMaxVal) {
        this.maxValueCodeChanged = false
        this.maxValueChangedByUser = true
      }
    },
    // Benyttes af input felterne, så mærkering af tallet ikke ender med at overlay lukkes, hvis cursoren ender udenfor
    // dragStart og dragEnd der bruges til slider eventet genbruges her
    keepOpenOnSelect() {
      this.dragStart()
      document.addEventListener('mouseup', this.endKeepOpenOnSelect)
    },
    endKeepOpenOnSelect() {
      this.dragEnd()
      document.removeEventListener('mouseup', this.endKeepOpenOnSelect)
    },
    // low value validation, to handle correction of min value, supporting manual input, lower then the initial minimum value
    getMinBounds(val) {
      const tempInterval = this.interval * 10
      if (
        this.options.min >= val &&
        this.minValLimit != null &&
        val < this.minValLimit
      ) {
        return val
      } else if (this.minVal > val) {
        return (val * 10 - (val == 0 ? 0 : tempInterval)) / 10
      } else {
        return (this.minVal * 10 - (val == 0 ? 0 : tempInterval)) / 10
      }
    },
    // high value validation, to handle correction of max value, supporting manual input, higher then the initial maximum value
    getMaxBounds(val) {
      const tempInterval = this.interval * 10
      if (
        this.options.max <= val &&
        this.maxValLimit != null &&
        val > this.maxValLimit
      ) {
        return val
      } else if (this.maxVal < val) {
        return (val * 10 + tempInterval) / 10
      } else {
        return (this.maxVal * 10 + tempInterval) / 10
      }
    },
    // when focusing an input field on matching outside bounds, we adapt/manipulate the shown value
    setDigitOnFocus(isMaxVal) {
      if (isMaxVal) {
        if (this.tempValue[1] != this.asFormattedString(this.options.max))
          return
        this.maxValueCodeChanged = true
        const newVal = (
          this.getFloat(this.tempValue[1]) - this.options.interval
        ).toString()
        this.tempValue[1] = newVal
      } else {
        if (
          this.tempValue[0] == '0' ||
          this.tempValue[0] != this.asFormattedString(this.options.min)
        )
          return
        this.minValueCodeChanged = true
        const newVal = (parseInt(this.tempValue[0]) < 0
          ? 0
          : this.getFloat(this.tempValue[0]) + this.options.interval
        ).toString()
        this.tempValue[0] = newVal
      }
    },
    // checking whether or not to run tempValue watch-handler, returning either true or false
    ignoreHandlerTrigger(val) {
      if (this.postBlurChangesDone) {
        // resetting to false in tempValue watch handler
        return false
      } else if (this.minValueCodeChanged) {
        return true
      } else if (this.maxValueCodeChanged) {
        return true
      }
      if (val[0] === '' || val[1] === '') return true
      if (
        val[0] == this.value[0].toString().replace('.', ',') &&
        val[1] == this.value[1].toString().replace('.', ',')
      ) {
        return true
      }

      const lowValueChanged =
        parseFloat(this.sanitizeNumberString(val[0].toString())) !=
        this.value[0]
      const highValueChanged =
        parseFloat(this.sanitizeNumberString(val[1].toString())) !=
        this.value[1]
      if (
        (lowValueChanged && parseFloat(val[0]) < this.minValLimit) ||
        (highValueChanged && parseFloat(val[1]) > this.maxValLimit)
      ) {
        return true
      }
      // Hvis der er sat et komma eller punktum og brugeren fortsat er i feltet, skal vi ikke ændre indtastningen, da vi så fjerner punktum/komma
      if (
        this.doIgnoreCommaAndDot(val[0]) &&
        this.doIgnoreCommaAndDot(val[1])
      ) {
        return true
      }

      return false
    },
    doIgnoreCommaAndDot(val) {
      if (
        val.toString().replace('.', ',').indexOf(',') ==
        this.doIgnoreCommaAndDotCheck(val)
      )
        return true

      return false
    },
    doIgnoreCommaAndDotCheck(val) {
      if (val.toString().length - 1) return true
      if (
        parseFloat(val).toString().length != val.length &&
        val.toString().charAt(val.toString().length - 1) === '0'
      )
        return true
      return false
    },
    getEmitMinValue() {
      if (this.value[0] == 0 || this.value[0] == this.options.min) {
        return null
      }

      return this.value[0]
    },
    getEmitMaxValue() {
      if (this.value[1] == this.options.max) {
        return null
      }

      return this.value[1]
    },
    emitValues(countAds = false) {
      if (this.isHidden) return
      if (this.valueHandlerTimeout != null) {
        clearTimeout(this.valueHandlerTimeout)
      }
      this.valueHandlerTimeout = setTimeout(() => {
        const item = {},
          summaryOptions = []

        item.min = this.getEmitMinValue()
        item.max = this.getEmitMaxValue()

        // Min value
        if (item.min !== null) {
          let summaryValue = item.min.toString()

          if (this.showThousandSeparator) {
            summaryValue = thousandSeparator(summaryValue)
          }

          if (this.valPostfix !== null) {
            summaryValue += ` ${this.valPostfix}`
          }

          summaryOptions.push({
            name: this.$parent.title + ' fra ' + summaryValue,
            clearSelf: this.clearMin,
            type: this.searchType + '_min',
          })
        }

        // Max value
        if (item.max !== null) {
          let summaryValue = item.max.toString()

          if (this.showThousandSeparator) {
            summaryValue = thousandSeparator(summaryValue)
          }

          if (this.valPostfix !== null) {
            summaryValue += ` ${this.valPostfix}`
          }

          summaryOptions.push({
            name: this.$parent.title + ' op til ' + summaryValue,
            clearSelf: this.clearMax,
            type: this.searchType + '_max',
          })
        }

        item.type = this.searchType

        this.eventBus.emit('search-request-callback', {
          value: item,
          searchType: this.searchType,
          summaryOptions: summaryOptions,
          countAds: countAds,
        })
      }, 350)
    },
    correctValues(isMaxVal) {
      let newVal
      if (
        !isMaxVal &&
        this.minValueCodeChanged &&
        this.getFloat(this.tempValue[0]) == this.value[0] + this.interval
      ) {
        this.minValueCodeChanged = false
        newVal = this.value[0]
      } else if (
        isMaxVal &&
        this.maxValueCodeChanged &&
        this.getFloat(this.tempValue[1]) == this.value[1] - this.interval
      ) {
        this.maxValueCodeChanged = false
        newVal = this.value[1]
      } else if (
        !isMaxVal &&
        this.getFloat(this.tempValue[0]) != this.value[0] + this.interval
      ) {
        this.postBlurChangesDone = true
        if (this.getFloat(this.tempValue[0]) < this.minValLimit) {
          newVal =
            (this.minValLimit * 10 -
              (this.minValLimit > 0 ? this.options.interval * 10 : 0)) /
            10
        } else {
          newVal = this.getFloat(this.tempValue[0])
        }
      } else if (
        isMaxVal &&
        this.getFloat(this.tempValue[1]) != this.value[1] - this.interval
      ) {
        this.postBlurChangesDone = true
        if (this.getFloat(this.tempValue[1]) > this.maxValLimit) {
          newVal = (this.maxValLimit * 10 + this.options.interval * 10) / 10
        } else {
          newVal = this.getFloat(this.tempValue[1])
        }
      }

      if (!newVal) return

      if (isMaxVal) {
        this.tempValue[1] = this.asFormattedString(newVal)
      } else {
        this.tempValue[0] = this.asFormattedString(newVal)
      }
    },
    clear() {
      this.value[0] = this.options.min
      this.value[1] = this.options.max
      this.$refs.slider.setValue([this.options.min, this.options.max])

      this.emitValues(this.isMounted)
    },
    clearMin() {
      this.value[0] = this.options.min
      this.$refs.slider.setValue([this.options.min, this.value[1]])

      this.emitValues(this.isMounted)
    },
    clearMax() {
      this.value[1] = this.options.max
      this.$refs.slider.setValue([this.value[0], this.options.max])

      this.emitValues(this.isMounted)
    },
    dragStart() {
      app.mitt.emit('overlay-inclosable', app)
    },
    dragEnd() {
      app.mitt.emit('overlay-closable', app)
    },
    isActiveElement(refElement) {
      return refElement === document.activeElement
    },
    blurIfActive() {
      if (
        (this.minValueChangedByUser &&
          this.isActiveElement(this.$refs[this.searchType + '_min'])) ||
        (this.maxValueChangedByUser &&
          this.isActiveElement(this.$refs[this.searchType + '_max']))
      ) {
        document.activeElement.blur()
      }
    },
  },
  watch: {
    tempValue: {
      deep: true,
      handler(val) {
        if (this.tempValueHandlerTimeout != null) {
          clearTimeout(this.tempValueHandlerTimeout)
        }
        if (this.ignoreHandlerTrigger(val)) return

        this.tempValueHandlerTimeout = setTimeout(
          () => {
            this.postBlurChangesDone = false
            let lowVal = parseFloat(
              this.sanitizeNumberString(val[0].toString())
            )
            if (isNaN(lowVal)) lowVal = this.value[0]
            const lowValInput = lowVal
            let highVal = parseFloat(
              this.sanitizeNumberString(val[1].toString())
            )
            if (isNaN(highVal)) highVal = this.value[1]
            const highValInput = highVal

            if (!this.minValueChangedByUser && !this.maxValueChangedByUser)
              return

            let tempLowVal = lowVal * 10
            let tempHighVal = highVal * 10
            const tempInterval = this.options.interval * 10
            const tempMinValLimit = this.minValLimit * 10
            const tempMaxValLimit = this.maxValLimit * 10

            // checking for out of bounds/limit inputs
            this.minBoundsBroken = false
            this.maxBoundsBroken = false
            if (this.minValueChangedByUser && tempLowVal < 0) {
              tempLowVal = 0
            } else if (
              this.minValueChangedByUser &&
              tempLowVal < tempMinValLimit
            ) {
              this.minBoundsBroken = true
              tempLowVal = tempMinValLimit
            }
            if (
              this.maxValueChangedByUser &&
              this.maxValLimit != null &&
              tempHighVal > tempMaxValLimit
            ) {
              this.maxBoundsBroken = true
              tempHighVal = tempMaxValLimit
            }

            // fixing changed value to match interval in corresponding direction
            if (this.lockedInterval) {
              const lowVal = this.fixToInterval(tempLowVal / 10, false) * 10
              const highVal = this.fixToInterval(tempHighVal / 10, true) * 10
              this.minValueChangedByUser
                ? (tempLowVal = lowVal)
                : (tempHighVal = highVal)
            }

            // Manuel indtastning krydser min/max værdierne
            if (tempLowVal > tempHighVal) {
              // min værdi overstiger nuværende maximum værdi
              if (
                this.minValueChangedByUser &&
                tempLowVal > this.options.max * 10 - tempInterval
              ) {
                // min værdi sat til/over den absolutte maximumværdi
                if (tempLowVal >= tempMaxValLimit) {
                  tempLowVal = tempMaxValLimit
                  tempHighVal = tempMaxValLimit + tempInterval
                } else {
                  tempHighVal = tempLowVal + tempInterval
                }
                this.options.max = tempHighVal / 10
                this.options.min = this.minVal
                this.minBoundsBroken = false
                this.maxBoundsBroken = true
              }
              // max værdi sat lavere end nuværende minimum værdi
              else if (
                !this.minValueChangedByUser &&
                tempHighVal < this.options.min * 10 + tempInterval
              ) {
                // max værdi sat til/under den absolutte minimumværdi
                if (tempHighVal <= tempMinValLimit && tempMinValLimit === 0) {
                  tempLowVal = tempMinValLimit
                  tempHighVal = tempMinValLimit + tempInterval
                } else if (tempHighVal <= tempMinValLimit) {
                  tempHighVal = tempMinValLimit
                  tempLowVal = tempHighVal - tempInterval
                } else if (tempHighVal - tempInterval < tempMinValLimit) {
                  tempLowVal = tempMinValLimit - tempInterval
                } else {
                  tempLowVal = tempHighVal - tempInterval
                }
                this.options.min = tempLowVal / 10
                this.options.max = this.maxVal
                this.minBoundsBroken = true
                this.maxBoundsBroken = false
              }
              // Maxværdi sat, så min værdien trykkes under 0 uden at ramme sweetspot for "under" trigger
              else if (
                !this.minValueChangedByUser &&
                tempHighVal - tempInterval < 0
              ) {
                tempLowVal = 0 - tempInterval
              }
              // Værdierne overlapper, men bryder ikke bounds
              else {
                if (this.minValueChangedByUser) {
                  tempHighVal = tempLowVal + tempInterval
                } else {
                  tempLowVal = tempHighVal - tempInterval
                }
                this.minBoundsBroken = false
                this.maxBoundsBroken = false
              }
            }

            lowVal = tempLowVal / 10
            highVal = tempHighVal / 10

            const tempMin = this.getMinBounds(lowVal)
            const tempMax = this.getMaxBounds(highVal)

            const correctBoundsFirst =
              (this.minValueChangedByUser &&
                tempMin <= this.minVal &&
                tempMin <= this.value[0]) ||
              (this.maxValueChangedByUser &&
                tempMax >= this.maxVal &&
                tempMax >= this.value[1])

            if (
              correctBoundsFirst &&
              (this.options.min != tempMin || this.options.max != tempMax)
            ) {
              this.minValueChangedByUser
                ? (this.options.min = tempMin)
                : (this.options.max = tempMax)
            }

            if (this.minBoundsBroken) {
              if (lowValInput <= tempMin) {
                lowVal = tempMin
              }
            }
            if (this.maxBoundsBroken) {
              if (highValInput >= tempMax) {
                highVal = tempMax
              }
            }

            setTimeout(() => {
              if (this.minValueChangedByUser) {
                this.value[0] = lowVal
                this.tempValue[0] = lowVal.toString().replace('.', ',')
                this.$refs.slider.setValue([lowVal, this.value[1]])
              } else {
                this.value[1] = highVal
                this.tempValue[1] = highVal.toString().replace('.', ',')
                this.$refs.slider.setValue([this.value[0], highVal])
              }

              // this.value = [lowVal, highVal]
              // this.tempValue = [
              //   lowVal.toString().replace('.', ','),
              //   highVal.toString().replace('.', ','),
              // ]
              if (
                ((this.maxBoundsBroken && this.maxValueChangedByUser) ||
                  (this.minBoundsBroken && this.minValueChangedByUser)) &&
                document.activeElement != null
              ) {
                this.blurIfActive()
              }

              if (
                !correctBoundsFirst &&
                (this.options.min != tempMin || this.options.max != tempMax)
              ) {
                this.minValueChangedByUser
                  ? (this.options.min = tempMin)
                  : (this.options.max = tempMax)
              }

              this.minValueChangedByUser = false
              this.maxValueChangedByUser = false

              this.emitValues(this.isMounted)
            }, 100)
          },
          this.postBlurChangesDone ? 0 : 1500
        )
      },
    },
    value: {
      handler(val) {
        let tempLowVal
        let tempHighVal
        if (this.isDecimal(this.options.interval)) {
          tempLowVal = Math.round(val[0] * 10) / 10
          tempHighVal = Math.round(val[1] * 10) / 10
        } else {
          tempLowVal = Math.round(val[0])
          tempHighVal = Math.round(val[1])
        }

        if (
          (tempLowVal.toString().replace('.', ',') == this.tempValue[0] ||
            this.isActiveElement(this.$refs[this.searchType + '_min'])) &&
          (tempHighVal.toString().replace('.', ',') == this.tempValue[1] ||
            this.isActiveElement(this.$refs[this.searchType + '_max']))
        ) {
          return
        }

        if (document.activeElement != null) {
          this.blurIfActive()
        }
        // checking if over/under values has been hit, setting a 1 interval as minimum distance
        this.options.minRange =
          (tempLowVal == this.options.min && this.options.min != 0) ||
            tempHighVal == this.options.max
            ? this.options.interval
            : 0
        //** START - Check på om brugeren sætter begge sliders helt i højre eller venstre. Dette kunne resultere i en min value højere end max og omvendt. Dette er et fix på dette. */
        if (tempLowVal === this.options.max) {
          tempLowVal = this.options.max - this.options.interval
        }
        if (tempHighVal === this.options.min) {
          tempHighVal = this.options.min + this.options.interval
        }
        //** END */

        this.tempValue = [
          tempLowVal.toString().replace('.', ','),
          tempHighVal.toString().replace('.', ','),
        ]
        this.value = [tempLowVal, tempHighVal]

        this.emitValues(this.isMounted)
      },
    },
  },
  created() {
    if (this.isDecimal(this.interval)) {
      const newInterval = this.fixDecimal(this.interval)
      this.options.interval = newInterval == 0 ? 0.1 : newInterval
    } else {
      this.options.interval = this.interval
    }

    // finding out whether or not the given selected value is a max/min value

    let tempMinSelected
    if (this.selectedMin == null) {
      tempMinSelected =
        this.minVal == 0
          ? this.minVal
          : (this.minVal * 10 - this.interval * 10) / 10
    } else if (this.selectedMin <= 0 || this.selectedMin < this.minValLimit) {
      tempMinSelected = this.minVal
    } else {
      tempMinSelected = this.selectedMin
    }

    let tempMaxSeleted
    if (this.selectedMax == null) {
      tempMaxSeleted = this.maxVal + this.interval
    } else if (this.selectedMax > this.maxValLimit) {
      tempMaxSeleted = this.maxVal
    } else {
      tempMaxSeleted = this.selectedMax
    }

    // making sure min/max value isn't put in opposite order
    if (tempMinSelected > tempMaxSeleted) {
      const temp = tempMinSelected
      tempMinSelected = tempMaxSeleted
      tempMaxSeleted = temp
    }

    this.value = [tempMinSelected, tempMaxSeleted]

    // Setting deafult options min value for the slider
    if (this.selectedMin == null) {
      // If -> the selected min value is null we go with the lower bounds to get the "under XXXX" result
      this.options.min = tempMinSelected
    } else if (this.selectedMin >= this.minVal) {
      // Else If -> The selected min value is bigger than or equal to the given minVal; then options.min will be set to minVal minus interval (standard)
      this.options.min =
        (this.minVal * 10 -
          (this.minVal == 0 ? 0 : this.options.interval) * 10) /
        10
    } else {
      // Else -> selected min is lower then the minVal. To avoid out of bounds in 'vue-slider-component', options.min is set like above, but based from the tempMinSelected value
      this.options.min =
        (tempMinSelected * 10 -
          (tempMinSelected == 0 ? 0 : this.options.interval) * 10) /
        10
    }

    // Setting deafult options max value for the slider
    if (this.selectedMax == null) {
      // If -> the selected max value is null, we go with the upper bounds to get the "over XXXX" result
      this.options.max = tempMaxSeleted
    } else if (this.selectedMax <= this.maxVal) {
      // Else If -> The selected max value is less than or equal to the given maxVal; then options.max will be set to minVal plus interval (standard)
      this.options.max = this.maxVal + this.options.interval
    } else {
      // Else -> selected max is higher then the maxVal. To avoid out of bounds in 'vue-slider-component', options.max is set like above, but based from the tempMaxSelected value
      this.options.max = tempMaxSeleted + this.options.interval
    }

    const tmp = Object.assign([], this.value)
    this.tempValue = tmp.map(e => e.toString().replace('.', ','))
    if (this.selectedMin != null || this.selectedMax != null) {
      this.emitValues(this.isMounted)
    }
  },
  mounted() {
    this.isMounted = true
  },
}
</script>

<style lang="scss">
@import 'Slider';
@import 'VueSlider';
</style>
