<template>
  <div
    class="overlay"
    :class="{
      'overlay--open': isOpen,
      'overlay--disabled': isDisabled,
      'overlay--subchild': hasOverlayChildren(),
      'overlay--single': !hasOverlayChildren(),
      'overflow-hidden-sm': isChildOpen,
    }"
  >
    <p class="overlay__title" v-html="title"></p>
    <button
      @click.prevent="emitState('overlay-toggle')"
      class="overlay__button"
      type="menu"
      ref="overlayButton"
      @keydown.down.prevent="arrowDown"
      @keydown.up.prevent="arrowUp"
      @keyup.esc="focusOff"
    >
      <div class="overlay__text-container">
        <p
          class="overlay__text"
          v-html="selectedTitle ? selectedTitle : title"
        ></p>
        <p
          v-if="!hideSelectText && entries.length > 1"
          class="search-indicator__text"
        >
          ({{ entries.length }})
        </p>
        <span v-if="entries.length > 0" class="search-indicator"></span>
      </div>
      <Arrow :direction="isOpen ? 'down' : 'right'" />
    </button>
    <div class="overlay__container">
      <div
        class="overlay__top-container"
        :class="{
          'overlay__top-container--fixed': !clearSearchActivated,
        }"
      >
        <button
          v-if="true"
          @click.prevent="emitState('overlay-close')"
          class="overlay__top"
          type="button"
        >
          <Arrow direction="left"></Arrow>
        </button>
        <div v-else class="overlay__placeholder"></div>
        <div class="overlay__top" v-html="title"></div>
        <button
          @click.prevent="eventBus.emit('overlay-close-all')"
          class="overlay__top"
          type="button"
        >
          <div aria-label="Luk" class="icon--plus icon--rotate"></div>
        </button>
      </div>
      <div
        class="overlay__content-container"
        :class="{
          'overlay__content-container--fixed-top': !clearSearchActivated,
        }"
      >
        <component
          v-for="component in children"
          v-bind="component.props"
          :key="component.id"
          :is="component.type"
          :id="component.id ? component.id : id"
          :catch-search-type="component.catchSearchType"
          :event-bus="eventBus"
          :proxy-bus="proxyBus"
          :product-type-id="productTypeId"
          :search-request="searchRequest"
          :ads-count="adsCount"
          :product-type-name-singular="productTypeNameSingular"
          :product-type-name-plural="productTypeNamePlural"
          :is-open="isOpen"
          :search-types="component.searchTypes"
        ></component>
        <slot></slot>
      </div>
      <div v-if="clearSearchActivated">
        <ClearSearch
          class="clear-search--fixed-bottom"
          :hide-counter="hideCounter"
          :event-bus="eventBus"
          :children="children"
          :ads-count="adsCount"
          :search-request="searchRequest"
          :product-type-name-singular="productTypeNameSingular"
          :product-type-name-plural="productTypeNamePlural"
        ></ClearSearch>
      </div>
    </div>
  </div>
</template>

<script>
import Arrow from '@shared/Arrow/Arrow.vue'

import ClearSearch from '@search/ClearSearch/ClearSearch.vue'

import UidMixin from '@shared/uid-mixin.vue'
import SearchComponentsMixin from '@search/search-components-mixin.vue'

import mitt from 'mitt'

export default {
  mixins: [SearchComponentsMixin, UidMixin],
  data() {
    return {
      isDisabled: false,
      isOpen: false,
      isChildOpen: false,
      proxyBus: mitt(),
      entries: [],
    }
  },
  components: {
    Arrow,
    ClearSearch,
  },
  props: {
    title: { default: '', type: String },
    id: { default: '', type: String },
    children: {
      default: () => {
        return []
      },
      type: Array,
    },
    eventBus: { default: null, type: Object },
    selectedTitle: { default: null, type: String },
    hideSelectText: { default: false, type: Boolean },
    productTypeId: { default: null, type: Object },
    adsCount: { default: 0, type: Number },
    searchRequest: { default: null, type: Array },
    clearSearchActivated: { default: true, type: Boolean },
    productTypeNameSingular: { default: '', type: String },
    productTypeNamePlural: { default: '', type: String },
    hideCounter: { default: false, type: Boolean },
    searchTypes: {
      default: () => {
        return []
      },
      type: Array,
    },
  },
  watch: {
    children: {
      deep: true,
      handler() {
        this.updateDisabledState()
      },
    },
    searchRequest: {
      deep: true,
      handler(newValue) {
        // clean slate - reset to nothing and then get new values
        this.entries.splice(0, this.entries.length)

        // find searchRequest matching this
        newValue.forEach(searchRequest => {
          if (this.children.length === 0) return

          this.children.forEach(child => {
            // figure out if type matches
            if (!this.searchTypes.includes(searchRequest.type)) return

            // IF THIS IS SLIDER
            if (child.type === 'Slider') {
              this.entries.push({ value: searchRequest.type })
            }

            // IF THIS IS OVERLAY CHILD
            if (
              child.type === 'Overlay' &&
              child.searchTypes.includes(searchRequest.type)
            ) {
              this.entries.push({ value: searchRequest.type })
            }

            // IF THIS IS DISTANCE
            if (child.type === 'Distance') {
              this.entries.push({ value: searchRequest.type })
            }

            // IF THIS IS PRICE
            if (child.type === 'Price') {
              // Her bygger vi navnet til price
              const value = searchRequest.value.paymentTypeName
              // if no duplicate
              this.entries.push({ value: value })
            }

            // IF THIS IS A LIST
            // List is a special case since we have more than one list
            // i.e. ModelList, SearchList, List
            if (child.props.items !== undefined) {
              // now search for same value
              child.props.items.forEach(item => {
                // look for duplicate
                if (
                  this.entries.filter(entry => entry.value === item.value)
                    .length > 0
                )
                  return

                // some lists have items here
                //  ProductList, Make (normal list) for instance
                if (item.value === searchRequest.value) {
                  // if no duplicate
                  this.entries.push({ value: item.value })
                }

                // some lists have items on other items
                //   ModelList for instance
                if (item.items !== undefined) {
                  item.items.forEach(childItem => {
                    // look for duplicate
                    if (
                      this.entries.filter(
                        entry => entry.value === childItem.value
                      ).length === 0
                    ) {
                      if (searchRequest.value === childItem.value) {
                        this.entries.push({ value: childItem.value })
                      }
                    }

                    // Some Models have children (model-class) that needs to be searched
                    if (childItem.children !== undefined) {
                      childItem.children.forEach(childItemChild => {
                        if (searchRequest.value === childItemChild.value) {
                          this.entries.push({ value: childItem.value })
                        }
                      })
                    }
                  })
                }
              })
            }
          })
        })
      },
    },
  },
  methods: {
    emitState(stateChange) {
      this.eventBus.emit(stateChange, this)
    },
    hasOverlayChildren() {
      return !this.children || this.children.find(e => e.type === 'Overlay')
    },
    updateDisabledState() {
      if (this.children === null) return

      let isDisabled = false
      this.children.forEach(child => {
        if (child.type === 'ModelList') {
          if (child.props.items.length === 0) {
            isDisabled = true
          }
        }
      })

      this.isDisabled = isDisabled
    },
    focusOn() {
      this.$refs.overlayButton.focus()
    },
    focusOff(event) {
      if (event) {
        if (this.isOpen) {
          this.emitState('overlay-toggle')
        } else {
          document.activeElement.blur()
        }
      }
    },
    arrowUp(event) {
      if (event) {
        event.stopPropagation()

        if (this.isOpen) {
          this.emitState('overlay-toggle')
        }
      }
    },
    arrowDown(event) {
      if (event) {
        event.stopPropagation()

        if (!this.isOpen) {
          this.emitState('overlay-toggle')
        } else {
          const acceptedTypes = ['SearchList', 'List', 'ModelList']
          if (
            this.children.length == 1 &&
            acceptedTypes.includes(this.children[0].type)
          ) {
            this.proxyBus.emit('init-smartlist')
          }
        }
      }
    },
  },
  created() {
    this.updateDisabledState()

    if (this.proxyBus) {
      this.proxyBus.on('exit-smartlist', this.focusOn)
    }
  },
}
</script>

<style lang="scss">
@import 'Overlay.scss';
@import '@search/SearchIndicator.scss';
</style>
