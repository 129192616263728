<template>
  <p class="password-strength" v-if="strengthValue.length > 0">
    <span>Kodeordets styrke:</span>
    <span
      :class="[
        strengthValueClass.length > 0
          ? 'password-strength__value--' + strengthValueClass
          : '',
      ]"
      class="password-strength__value"
      >{{ strengthValue }}</span
    >
  </p>
</template>

<script>
export default {
  props: {
    minLength: { default: 0, type: Number },
    password: { default: undefined, type: String },
  },
  data() {
    return {
      strengthValue: '',
      strengthValueClass: '',
    }
  },
  watch: {
    // regex used from https://stackoverflow.com/a/59317682/313106
    // Determine password strength
    password(newPassword) {
      if (newPassword.length < this.minLength) {
        this.strengthValue = ''
        this.strengthValueClass = ''
        return
      }

      // figure out points
      let points = 0

      // check for at least 2 letters
      if (/(?=(.*[A-Za-z]){2,})/.test(newPassword)) {
        points++
      }

      // check for at least 2 integers
      if (/(?=(.*[0-9]){2,})/.test(newPassword)) {
        points++
      }

      // check for at least 1 special character
      if (/(?=(.*[!@#$%^&*()\-__+.]){1,})/.test(newPassword)) {
        points++
      }

      // check if the length value is 2 more that required
      if (newPassword.length >= this.minLength + 2) {
        points++
      }

      // set strengthValue based on points
      if (points >= 1) {
        this.strengthValue = 'Svag'
        this.strengthValueClass = 'weak'
      }

      if (points >= 3) {
        this.strengthValue = 'Mellem'
        this.strengthValueClass = 'medium'
      }

      if (points >= 4) {
        this.strengthValue = 'Stærk'
        this.strengthValueClass = 'strong'
      }

      // check if the length value is 12 more that required
      if (newPassword.length >= this.minLength + 12) {
        points++
      }

      if (points >= 5) {
        this.strengthValueClass = 'extreme'
      }
    },
  },
}
</script>

<style lang="scss">
@import './PasswordStrength.scss';
</style>
