<script>
import List from '@search/List/List.vue'
import ListHorizontal from '@search/ListHorizontal/ListHorizontal.vue'
import ListHorizontalContainer from '@search/ListHorizontal/ListHorizontalContainer.vue'
import SearchList from '@search/SearchList/SearchList.vue'
import Slider from '@search/Slider/Slider.vue'
import ProductType from '@search/ProductType/ProductType.vue'
import ModelList from '@search/ModelList/ModelList.vue'
import TextSearch from '@search/TextSearch/TextSearch.vue'
import TextSearchWithAutocomplete from '@search/TextSearchWithAutocomplete/TextSearchWithAutocomplete.vue'
import AdvancedSearch from '@search/AdvancedSearch/AdvancedSearch.vue'
import Distance from '@search/Distance/Distance.vue'
import Price from '@search/Price/Price.vue'

export default {
  components: {
    List,
    ListHorizontal,
    ListHorizontalContainer,
    Slider,
    ProductType,
    SearchList,
    ModelList,
    TextSearch,
    TextSearchWithAutocomplete,
    AdvancedSearch,
    Distance,
    Price,
  },
}
</script>
