<template>
  <div class="searchlist">
    <label class="sr-only" :for="searchType + 'search-list' + uid">
      {{ searchPlaceholder }}
    </label>
    <div class="input input--search searchlist__search">
      <div class="searchlist__input-container">
        <input
          class="input--search"
          type="text"
          @input="searchString = $event.target.value"
          @keydown.down.prevent="goToSmartlist"
          @keydown.up.prevent="goToParent"
          :value="searchString"
          :title="searchPlaceholder"
          :placeholder="searchPlaceholder"
          :id="searchType + 'search-list' + uid"
          ref="searchListInput"
          autocomplete="off"
        />
        <ClearInput
          :value="searchString"
          :event-bus="eventBus"
          :search-icon="true"
        />
      </div>
    </div>
    <List
      :type="type"
      :search-type="searchType"
      :items="searchItems"
      :event-bus="eventBus"
      :id="id"
      :single-select="singleSelect"
      :close-on-select="closeOnSelect"
      :proxy-bus="internalBus"
    ></List>
  </div>
</template>

<script>
import List from '@search/List/List.vue'
import ListPropsMixin from '@search/List/list-props-mixin.vue'
import UidMixin from '@shared/uid-mixin.vue'
import ClearInput from '@shared/ClearInput/ClearInput.vue'

import {
  decodeHTMLEntities,
  sanitizeRegexString,
  highlightTextInString,
} from '@/functions'

import mitt from 'mitt'

export default {
  components: {
    List,
    ClearInput,
  },
  mixins: [ListPropsMixin, UidMixin],
  name: 'SearchList',
  // included to satisfy typescript
  props: {
    eventBus: { default: null, type: Object },
    proxyBus: { default: null, type: Object },
    type: { default: 'checkbox', type: String },
    searchType: { default: null, type: String },
    id: { default: '', type: String },
    items: { default: null, type: Array },
    searchPlaceholder: { default: 'Søg...', type: String },
    singleSelect: { default: false, type: Boolean },
    closeOnSelect: { default: false, type: Boolean },
    isOpen: { default: false, type: Boolean },
  },
  data() {
    return {
      searchString: '',
      searchItems: [],
      checkboxModel: [],
      radioModel: '',
      internalBus: mitt(),
    }
  },
  watch: {
    items(newValue) {
      this.updateItems(newValue)
      this.performSearch(this.searchString)
    },
    searchString(newString) {
      this.performSearch(newString)
    },
    isOpen(newValue) {
      if (newValue) {
        const owner = this
        setTimeout(() => {
          owner.$refs.searchListInput.focus()
        }, 50)
      } else {
        // clearing search, when the overlay is closed
        this.clearSearchString()
      }
    },
  },
  methods: {
    clearSearchString() {
      this.searchString = ''
      if (this.$refs.searchListInput) {
        this.$refs.searchListInput.focus()
      }
    },
    ///***** main function within this component - handling the actual search *****///
    /// during search a series of actions will occur:
    /// - ensuring selected elements will persist
    /// - sortorder kept neat
    /// - running the search
    ///   > child elements presented even though the parent elementName doesn't match the searchString
    /// - sorting the list and highlighting the matching part(s) of the elementNames
    performSearch(searchString) {
      // finding the selected id's
      let selectedIds = this.getSelectedIds()

      // parsing the origional dataset to searchItems, to start from a prestine state
      this.searchItems = JSON.parse(JSON.stringify(this.items))

      // reset no matter what incl. setting selected items
      this.resetSearchList(selectedIds, searchString.length > 0)

      // do nothing more if searchString is empty
      if (searchString.length === 0) return

      // sanitizing searchstring to escape characters with a regex functionality
      const sanitizedSearchString = sanitizeRegexString(searchString)

      // perform the actual the search
      this.searchItems.forEach(element => {
        // if element has already been hidden (duplicate) - we will do nothing
        if (element.isHidden) return

        element.name = decodeHTMLEntities(element.name)

        // check search string
        element.isHidden = !this.isPartialMatch(
          element.name,
          sanitizedSearchString
        )

        // if the element has children, they too will be checked for match against the searchString
        if (element.children !== undefined) {
          element.children.forEach(child => {
            if (this.isPartialMatch(child.name, sanitizedSearchString)) {
              child.isHidden = false
              // if at least one child is visible, so will the parent
              element.isHidden = false
            } else {
              child.isHidden = true
            }
          })
        }
      })

      // sort list with 'startsWith' matches first and map to searchItems array
      this.searchItems = this.sortAndHighlight(searchString)
    },
    ///***** gathering the id's of selected elements, returning a non-dublete array *****///
    getSelectedIds() {
      let idArr = []
      this.searchItems
        // filtering all selected items
        .filter(item => {
          if (item.selected) {
            return item
          }
        })
        // running through selected items, pushing their value to idArr, if not yet included
        .forEach(item => {
          if (!idArr.includes(item.value)) {
            idArr.push(item.value)
          }
        })
      return idArr
    },
    ///***** resetting the list setting isHidden, selected and removing dublicates a search is ongoing *****///
    resetSearchList(selectedIds, hasSearchString) {
      this.searchItems.forEach(item => {
        // setting all items visible, incl. child elements
        item.isHidden = false
        if (item.children !== undefined) {
          item.children.forEach(child => (child.isHidden = false))
        }

        // if searchString is present and the item is of type header, it will be hidden
        if (hasSearchString && item.isHeader) {
          item.isHidden = true
        }

        // if selected before reset, set as so again
        if (selectedIds.includes(item.value)) {
          item.selected = true
        }

        // if a search is ongoing, certain things will be checked as well
        if (hasSearchString) {
          // if the item is of type header, it will be hidden
          if (item.isHeader) {
            item.isHidden = true
          }

          // Checking for dublicates, hiding all but the last
          const hitsLength = this.searchItems.filter(c => c.name === item.name)
            .length

          if (hitsLength > 1) {
            // find items in this.searchItems
            for (let index = 0; index < hitsLength - 1; index++) {
              const foundDuplicate = this.searchItems.find(
                x => x.name === item.name && x.searchType === item.searchType
              )
              foundDuplicate.isHidden = true
            }
          }
        }
      })
    },
    ///***** checking item name against the searchString returning whether there's a partial match (at least) *****///
    isPartialMatch(name, searchString) {
      if (
        name
          .toString()
          .toLowerCase()
          .trim()
          .match(searchString.toLowerCase().trim()) !== null
      ) {
        return true
      }

      return false
    },
    ///***** sorting searchItems array, based on the searchString *****///
    /// ordering the 'startsWith' matches at the beginning followed by the other visible items and then the hidden ones
    /// isHidden items is included, to ensure selections among them are available for this.getSelectedIds
    sortAndHighlight(searchString) {
      let first = []
      let rest = []
      let hidden = []
      this.searchItems.forEach(element => {
        // already hidden elements will be pushed to the 'hidden' array, after which the iteration will be ended
        if (element.isHidden) {
          hidden.push(element)
          return
        }

        // noting whether the elementName startsWith the searchString, to later bring these to the top of the result
        const startsWith = element.name.toLowerCase().startsWith(searchString)

        // highlighting the searchString within the element name
        this.addSearchHighlight(element, searchString)
        // hihglighting child name as well, if any
        if (element.children !== undefined) {
          element.children.forEach(child => {
            if (child.isHidden) return
            this.addSearchHighlight(child, searchString)
          })
        }

        // adding element to either first if startsWith is true, or rest array if not
        if (startsWith) {
          first.push(element)
        } else {
          rest.push(element)
        }
      })
      // unifying the three arrays into one, returning this
      first.push.apply(first, rest)
      first.push.apply(first, hidden)
      return first
    },
    ///***** adding highlight to an element.name where the current searchString is present *****///
    /// if multiple occurrencies are present, all of these will be highlighted
    /// original case will be preseved using this function
    addSearchHighlight(element, searchString) {
      element.nameMatch = highlightTextInString(element.name, searchString)
    },
    check(selectedItem) {
      if (this.type == 'checkbox') {
        this.checkboxModel.push(selectedItem.value)
      } else {
        this.uncheckRadios(selectedItem)
        this.radioModel = selectedItem.value.toString()
      }
    },
    uncheckRadios(selectedItem) {
      let unselectList = this.searchItems.filter(
        item => item.selected && item.value !== selectedItem.value
      )
      if (unselectList.length > 0) {
        unselectList.forEach(item => {
          item.selected = false
        })
      }
    },
    uncheck(selectedItem) {
      this.checkboxModel.splice(
        this.checkboxModel.indexOf(selectedItem.value),
        1
      )
    },
    searchRequestCallback(options) {
      if (this.searchType != options.searchType) return

      if (options.selected) {
        this.check(options)
      } else {
        this.uncheck(options)
      }
    },
    updateItems(items) {
      items.forEach(item => {
        item.isHidden = false
      })

      this.searchItems = items
    },
    ///***** Emitting to make the searchList parent take focus from the input field *****///
    goToParent() {
      if (!this.proxyBus) return
      this.proxyBus.emit('exit-smartlist')
    },
    ///***** Triggered from input field on arrow down - letting the first valid listItem take focus *****///
    goToSmartlist() {
      if (!this.internalBus) return
      this.internalBus.emit('init-smartlist')
    },
    ///***** Triggered by the init-smartlist emit from parent - Setting focus on the input field *****///
    setInputFocus() {
      if (this.$refs.searchListInput) {
        this.$refs.searchListInput.focus()
      }
    },
    ///***** Used to trigger focus on the last valid item in the list *****///
    /// Used by [ModelList]
    goToSmartlistReverse() {
      if (!this.isOpen) return
      if (!this.internalBus) return
      this.internalBus.emit('init-smartlist-reverse')
    },
    ///***** Used when triggering next item, while focusing the last item of the list *****///
    /// Used by [ModelList] to jump from last [ListInput] to the next ModelListItem
    exitThroughBottom() {
      if (!this.proxyBus) return
      this.proxyBus.emit('exit-smartlist-bottom')
    },
  },
  beforeUnmount() {
    if (this.eventBus) {
      this.eventBus.off('search-request-callback', this.searchRequestCallback)
      this.eventBus.off('clear-input-string', this.clearSearchString)
    }

    if (this.proxyBus) {
      this.proxyBus.off('init-smartlist', this.setInputFocus)
      this.proxyBus.off('init-smartlist-reverse', this.goToSmartlistReverse)
    }

    if (this.internalBus) {
      this.internalBus.off('exit-smartlist', this.setInputFocus)
      this.internalBus.off('exit-smartlist-bottom', this.exitThroughBottom)
    }
  },
  created() {
    this.updateItems(this.items)

    if (this.eventBus) {
      this.eventBus.on('search-request-callback', this.searchRequestCallback)
      this.eventBus.on('clear-input-string', this.clearSearchString)
    }

    if (this.proxyBus) {
      this.proxyBus.on('init-smartlist', this.setInputFocus)
      this.proxyBus.on('init-smartlist-reverse', this.goToSmartlistReverse)
    }

    if (this.internalBus) {
      this.internalBus.on('exit-smartlist', this.setInputFocus)
      this.internalBus.on('exit-smartlist-bottom', this.exitThroughBottom)
    }
  },
}
</script>

<style lang="scss">
@import 'SearchList.scss';
</style>
